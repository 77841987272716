import React from 'react'
import './GlobalAbout.css';
import GLBNavbar from "../Navbar/Navbar";
import AboutGLB1 from './AboutGLB1/AboutGLB1';
import Footerglb from "../Footer/Footer"


function GlobalAbout() {
  return (
    <>
    <GLBNavbar />
    <AboutGLB1 />
    <Footerglb />
    


</>
  )
}

export default GlobalAbout


// import React from 'react';
import './Portal.css';
import UAEFooter from "../Footer/Footer";
import React from "react";
import UAENavbar from "../Navbar/Navbar";
import UAEChat from "../ChatBot/Chat";

const Portal = () => {
    return (
        <>
            <UAENavbar />
            <UAEChat />
            <div className='portal'>
                <p><span className='one'>To be launch</span><span className='two'> Soon</span> </p>
            </div>
            <UAEFooter />
        </>
    );
};

export default Portal;
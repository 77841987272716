import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  MenuItem,
  Card,
  CardContent,
  CardMedia,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Grid,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import BusinessIcon from "@mui/icons-material/Business";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// Define base API URL
const API_BASE_URL = "https://api.connexit.biz";

function EventRegistration() {
  const [step, setStep] = useState(0);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [userId, setUserId] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const steps = [
    "Select Event",
    "Validate User ID",
    "Review User Details",
    "Confirm Attendance",
  ];

  // Fetch events from the API on component mount
  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_BASE_URL}/upcommingeventname`);
        if (Array.isArray(response.data)) {
          const formattedEvents = response.data.map((event) => ({
            ...event,
            imageUrl: `data:image/jpeg;base64,${event.image_data}`, // Convert base64 to image URL
          }));
          setEvents(formattedEvents);
          setError(null);
        } else {
          setError("Unexpected data format.");
        }
      } catch (error) {
        console.error("Error fetching events:", error);
        setError("Error fetching events");
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const handleNext = () => setStep((prevStep) => prevStep + 1);
  const handleBack = () => setStep((prevStep) => prevStep - 1);

  const handleEventSelect = (event) => setSelectedEvent(event);

  const handleValidateUserId = async () => {
    setLoading(true);
    try {
      // Make API call with userId
      const response = await axios.get(`${API_BASE_URL}/getallupcomingeventregdata/${userId}`);

      const eventData = response.data.find((item) => item.ueid === selectedEvent.id);
      if (eventData) {
        // Check if the user's attendance is already marked as "Approved"
        if (eventData.atend === "Approved") {
          Swal.fire("Attendance Already Confirmed", "You have already marked attendance.", "info");
        } else {
          setUserDetails({
            name: eventData.name,
            designation: eventData.designation,
            companyName: eventData.companyname,
            email: eventData.email,
            contactNo: eventData.contactno,
            province: eventData.province,
            city: eventData.city,
            registrationId: eventData.urid, // Save urid for attendance
          });
          setError(null);
          handleNext();
        }
      } else {
        Swal.fire("Error", "Event details not found.", "error");
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
      setError("Error fetching user details");
      Swal.fire("Error", "Could not fetch user details.", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmAttendance = async () => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/attendeventreg/${userDetails.registrationId}`,
        { status: "confirmed" }
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Attendance Confirmed",
          text: "Thank you for confirming your attendance!",
          confirmButtonText: "OK",
        }).then(() => {
          setStep(0);
          setSelectedEvent(null);
          setUserId("");
          setUserDetails(null);
        });
      } else {
        Swal.fire("Error", "Could not confirm attendance.", "error");
      }
    } catch (error) {
      console.error("Error confirming attendance:", error);
      setError("Failed to confirm attendance");
      Swal.fire("Error", "Failed to confirm attendance.", "error");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        padding: 5,
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 600,
          bgcolor: "background.paper",
          boxShadow: 3,
          borderRadius: 2,
          p: { xs: 2, md: 4 },
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          Event Registration
        </Typography>

        <Stepper activeStep={step} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {/* Error display */}
        {error && (
          <Typography variant="body1" color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}

        {/* Step 1: Select Event */}
        {step === 0 && (
          <Box>
            <Typography variant="h6" mt={2}>
              Select an Event
            </Typography>
            <TextField
              select
              fullWidth
              label="Choose Event"
              value={selectedEvent?.id || ""}
              onChange={(e) =>
                handleEventSelect(events.find((evt) => evt.id === Number(e.target.value)))
              }
              sx={{ marginTop: 2 }}
            >
              {events.map((event) => (
                <MenuItem key={event.id} value={event.id}>
                  {event.title}
                </MenuItem>
              ))}
            </TextField>

            {selectedEvent && (
              <Card sx={{ marginTop: 3 }}>
                <CardMedia
                  component="img"
                  height="200"
                  image={selectedEvent.imageUrl}
                  alt={selectedEvent.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5">
                    {selectedEvent.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {selectedEvent.description}
                  </Typography>
                  <Box mt={2} display="flex" alignItems="center">
                    <CalendarTodayIcon />
                    <Typography variant="body2" ml={1}>
                      {selectedEvent.date}
                    </Typography>
                    <AccessTimeIcon sx={{ marginLeft: 2 }} />
                    <Typography variant="body2" ml={1}>
                      {selectedEvent.time}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              sx={{ marginTop: 3, width: "100%" }}
              disabled={!selectedEvent}
            >
              Next
            </Button>
          </Box>
        )}

        {/* Step 2: Validate User ID */}
        {step === 1 && (
          <Box>
            <Typography variant="h6" mt={2}>
              Validate User ID
            </Typography>
            <TextField
              fullWidth
              label="Enter Your User ID"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              sx={{ marginTop: 2 }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleValidateUserId}
              sx={{ marginTop: 2, width: "100%" }}
              disabled={!userId}
            >
              {loading ? <CircularProgress size={24} /> : "Validate"}
            </Button>
          </Box>
        )}

        {/* Step 3: Review User Details */}
        {step === 2 && userDetails && (
          <Box mt={3}>
            <Typography variant="h6" gutterBottom>
              User Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <PersonIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="body1">{userDetails.name}</Typography>
              </Grid>
              <Grid item xs={6}>
                <BusinessIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="body1">{userDetails.designation}</Typography>
              </Grid>
              <Grid item xs={6}>
                <EmailIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="body1">{userDetails.email}</Typography>
              </Grid>
              <Grid item xs={6}>
                <PhoneIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="body1">{userDetails.contactNo}</Typography>
              </Grid>
              <Grid item xs={6}>
                <LocationOnIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="body1">{userDetails.province}</Typography>
              </Grid>
              <Grid item xs={6}>
                <LocationOnIcon color="primary" sx={{ mr: 1 }} />
                <Typography variant="body1">{userDetails.city}</Typography>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              sx={{ marginTop: 3, width: "100%" }}
            >
              Next
            </Button>
          </Box>
        )}

        {/* Step 4: Confirm Attendance */}
        {step === 3 && (
          <Box>
            <Typography variant="h6" mt={2} gutterBottom>
              You're almost there!
            </Typography>
            <Typography variant="body1" mb={2}>
              By clicking “Attend Meeting,” you confirm your attendance for{" "}
              <strong>{selectedEvent.title}</strong>.
            </Typography>
            <Button
              variant="contained"
              color="success"
              endIcon={<CheckCircleIcon />}
              onClick={handleConfirmAttendance}
              sx={{ width: "100%" }}
            >
              Attend Meeting
            </Button>
          </Box>
        )}

        {/* Back Button */}
        {step > 0 && (
          <Button onClick={handleBack} sx={{ mt: 3, width: "100%" }}>
            Back
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default EventRegistration;

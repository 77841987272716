
import HeroSection from './Hero/Hero';
import WhoweareSection from './Who_we_are/Whoweare';
import Latest from './LatestEvents/Latest';
// import Test from './Testing/test';
import Vendors from './Vendors/Vendors';
import INDNavbar from "../Navbar/Navbar";
import INDChat from "../ChatBot/Chat";
import INDFooter from "../Footer/Footer";
import React from "react";


function Home() {
  return (
    <>
      <INDNavbar />
      <INDChat />

      <HeroSection />
      <WhoweareSection />
      <Vendors />
      <Latest />
      <INDFooter />
    </>
  );
}

export default Home;


// import React from 'react';
import './Portal.css';
import MDVFooter from "../Footer/Footer";
import React from "react";
import MDVNavbar from "../Navbar/Navbar";
import MDVChat from "../ChatBot/Chat";

const Portal = () => {
    return (
        <>
            <MDVNavbar />
            <MDVChat />
            <div className='portal'>
                <p><span className='one'>To be launch</span><span className='two'> Soon</span> </p>
            </div>
            <MDVFooter />
        </>
    );
};

export default Portal;
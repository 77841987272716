import React from 'react';
import './NewButton.css';

function NewBtn() {
  return (
    <div className='meetRowau'>
      <div className='feedbackbtnrowau'>

        <a
          href="https://forms.office.com/Pages/ResponsePage.aspx?id=fuSYqqNza0mmRNxa-fbwsfBpQjK7vGlNg0EwUHwk9rhUMjYyTE5LMkpCN0NZREY4VkVVNjVITUlaNC4u"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="feedback-buttonau">
            Feedbacks
          </button>
        </a>

      </div>

    </div>
  )
}

export default NewBtn
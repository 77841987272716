import React from 'react';
import './RegSec1.css';
import worldMapImage from '../.././../images/map/map trans.png'; // Adjust the path to your image

function RegSec1() {
    const scrollToNextSection = () => {
        const nextSection = document.querySelector('.reg-next-section');
        if (nextSection) {
            nextSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            <div className="reg-solutionglb">
                <h1 className="reg-main-heading">
                    Our regional offices
                </h1>
                <p className="reg-subheading">
                    Connex Information Technologies is a premier technology services firm with a robust presence across the Asia Pacific region. <br /> Our offices are strategically located to serve our clients effectively. <br />
                    We invite you to visit our offices and discover how we can empower your business with our innovative solutions.
                </p>
                <div className="reg-scroll-indicator" onClick={scrollToNextSection}>
                    <i className="fas fa-mouse"></i>
                </div>
            </div>

            <section className="reg-next-section">
                <div className="reg-two-columns">
                    <div className="reg-left-column">
                        <h1 className="reg-next-main-heading">
                            An ever-expanding global presence
                        </h1>
                        <p className="reg-next-subheading">
                            <p className="reg-next-subheading">
                                Since 2014, we have massively expanded our global coverage and now have offices in over 14 countries across 4 continents.
                            </p>
                        </p>
                        <p className="reg-next-details">
                            <strong>Interact with the map to locate your nearest office</strong>
                        </p>
                    </div>
                    <div className="reg-right-column">
                        <img src={worldMapImage} alt="World Map" className="reg-world-map" />
                    </div>
                </div>
            </section>
        </>
    );
}

export default RegSec1;
import React, { useState } from "react";
import "./Sec2.css";

function Sec2() {
  const pillars = [
    {
      name: "Perimeter and Internal Security",
      description: "Protect your organization with advanced security at both perimeter and internal levels to mitigate threats.",
    },
    {
      name: "Cyber Security Governance & Compliance",
      description: "Ensure adherence to security standards and compliance policies to safeguard your enterprise.",
    },
    {
      name: "Authentication & Identity Management",
      description: "Securely manage user identities and authentication to enhance data access control.",
    },
    {
      name: "Security Management",
      description: "Optimize and monitor security processes for effective protection against cyber threats.",
    },
    {
      name: "Endpoint Security",
      description: "Protect end-user devices from malware, ransomware, and other cyber threats.",
    },
    {
      name: "Networking",
      description: "Build robust, scalable, and secure networking infrastructure for modern enterprise needs.",
    },
    {
      name: "Data Center Infrastructure and Monitoring",
      description: "Manage and monitor data center performance for seamless operations.",
    },
    {
      name: "Server Storage & Backup Solutions",
      description: "Ensure reliable server performance and secure backups for business continuity.",
    },
    {
      name: "Smart and IoT Devices",
      description: "Leverage the power of IoT to drive innovation and operational efficiency.",
    },
    {
      name: "Unified Communications and Collaboration",
      description: "Enhance team collaboration and communication through integrated tools and platforms.",
    },
    {
      name: "Computer and Phone Accessories",
      description: "Equip your workforce with high-quality accessories to boost productivity.",
    },
    {
      name: "Audio and Video IoT",
      description: "Revolutionize multimedia experiences with cutting-edge audio and video IoT technologies.",
    },
    {
      name: "Microsoft",
      description: "Explore Microsoft's robust ecosystem for enterprise solutions and productivity tools.",
    },
  ];

  const [openCardIndex, setOpenCardIndex] = useState(null);

  const toggleCard = (index) => {
    setOpenCardIndex(openCardIndex === index ? null : index);
  };

  return (
    <div className="sec2-container">
      <h1 className="sec2-title">Browse by Solutions</h1>
      <div className="sec2-grid">
        {pillars.map((pillar, index) => (
          <div className="sec2-card-container" key={index}>
            <div className="sec2-card" onClick={() => toggleCard(index)}>
              <h6 className="sec2pillername">{pillar.name}</h6>
              <span className="arrowsec">{openCardIndex === index ? "˅" : "˄"}</span>
            </div>
            {openCardIndex === index && (
              <div className="sec2-dropdown">
                <p>{pillar.description}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Sec2;

import './Hero.css';
import React, { useEffect } from 'react';
import VideoFile from '../../../images/intro1.mp4'; // Replace with your actual video file path

function HeroSection() {
  useEffect(() => {
    // Add "no-background" class to body when HeroSection mounts
    document.body.classList.add('no-backgroundglb');

    // Cleanup: Remove "no-background" class when the component unmounts
    return () => {
      document.body.classList.remove('no-backgroundglb');
    };
  }, []);

  // Function to split text into letters and wrap each letter in a span
  const renderAnimatedText = (text) => {
    return text.split('').map((letter, index) => (
      <span key={index} style={{ '--index': index }}>
        {letter}
      </span>
    ));
  };

  // Function to scroll to the next section
  const scrollToNextSection = () => {
    const nextSection = document.querySelector('.next-sectiontest'); // Replace with the class or ID of your next section
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
     <section id="hero" className="section heroglb">
      <video
        className="hero-videoglb"
        autoPlay
        muted
        loop
        playsInline
      >
        <source src={VideoFile} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Scroll Indicator */}
      <div className="scroll-indicator" onClick={scrollToNextSection}>
        <i className="fas fa-mouse"></i>
      </div>

      <div className="content-wrapperglb">
        <div className="image-containerglb">
          <h1 className="qwer animated-text">#EMPOWERINGTechnology</h1>
        </div>

        <div className="text-containerglb">
          <div className="masking-container">
            <h1 className="main-textglb">Connecting the World Through Reliable Distribution</h1>
          </div>
          <div className="additional-textglb">
            <p>
              {renderAnimatedText("Empowering the journey towards")}
              <br /> 
              {renderAnimatedText("a secure and innovative digital future")}
            </p>
          </div>
        </div>
      </div>
    </section>
    
    
    <section className="next-sectiontest">
   
  </section></>
   
    
  );
}

export default HeroSection;
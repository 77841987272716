import "./Careers.css";
import SLFooter from "../Footer/Footer";
import React from "react";
import SLNavbar from "../Navbar/Navbar";
import SLChat from "../ChatBot/Chat";
import bgimg from "../../images/bd.jpg";

function Careers() {
  return (
    <>
      <SLNavbar />
      <SLChat />

      {/* Main Container with Background Image */}
      <div
        className="careers-container"
        style={{
          backgroundImage: `url(${bgimg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="careers-content" style={{ maxWidth: "1200px", width: "100%" }}>
          <h1 style={{ textAlign: "center", marginBottom: "20px", color: "white" }}>
            Welcome to Connex Careers: Where Innovation Meets Opportunity
          </h1>
          <hr/>
          <p style={{ textAlign: "center", color: "#b6fcf3" , marginTop:"50px"}}>
            At Connex Information Technologies, we don’t just build IT solutions; we create
            possibilities. As a leader in IT distribution, we empower businesses worldwide with
            cutting-edge technology and innovative services. Behind every success story is a
            passionate team of individuals committed to pushing boundaries and driving change.
          </p>
          <p style={{ textAlign: "center", marginBottom: "40px", color: "white" }}>
            Are you ready to join us and make your mark on the future of technology?
          </p>

          {/* Cards Section */}
          <div className="careers-section" style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
            <div className="careers-card">
              <h2 style={{ color: "#0258a8" }}>Why Work With Us?</h2>
              <ul>
                <li>
                  <strong>Innovative Culture:</strong> At Connex, innovation isn’t just a buzzword, it’s at the
                  core of everything we do. We encourage creative ideas, bold thinking, and embracing
                  challenges.
                </li>
                <li>
                  <strong>Professional Growth:</strong> We believe in unlocking your potential. With access
                  to learning opportunities, training programs, and mentorship, your career path will
                  be as dynamic as you are.
                </li>
                <li>
                  <strong>Global Impact:</strong> Be part of a team that influences industries across continents.
                  Collaborate with international partners and make a difference on a global scale.
                </li>
                <li>
                  <strong>Inclusive Environment:</strong> Our diverse workplace values individuality and fosters
                  collaboration. We celebrate differences because they drive innovation.
                </li>
              </ul>
            </div>

            <div className="careers-card">
              <h2 style={{ color: "#0258a8" }}>What We Offer</h2>
              <ul>
                <li>Competitive compensation and benefits</li>
                <li>A workplace where your ideas matter</li>
                <li>Opportunities to work with global clients and technologies</li>
                <li>A supportive and inclusive work environment</li>
                <li>Career development through tailored learning programs</li>
              </ul>
            </div>
          </div>

          <div
            className="careers-section"
            style={{
              marginTop: "40px",
              textAlign: "center",
              padding: "20px",
              borderRadius: "10px",
              background: "rgba(255, 255, 255, 0.7)",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h2 style={{ color: "#003566" }}>Join Us Today!</h2>
            <p style={{ marginBottom: "20px", color: "#555" }}>
              Are you ready to take the next step in your career? Explore opportunities at Connex and
              find your perfect fit. Whether you’re an experienced professional or a fresh graduate,
              we have a place for you to thrive.
            </p>
            <div
              className="careers-links"
              style={{ display: "flex", justifyContent: "center", gap: "20px" }}
            >
              <a
                href="#"
                className="explore-button"
                style={{
                  padding: "10px 20px",
                  // borderRadius: "5px",
                  backgroundColor: "#003566",
                  color: "#fff",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                Explore Open Positions
              </a>
              <a
                href="#"
                className="submit-button"
                style={{
                  padding: "10px 20px",
                  // borderRadius: "5px",
                  backgroundColor: "#007BFF",
                  color: "#fff",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                Submit Your Resume
              </a>
            </div>
            <p style={{ marginTop: "20px", color: "#555" }}>
              Your journey with Connex starts here. Together, let’s innovate, inspire, and lead the
              future of IT.
            </p>
          </div>
        </div>
      </div>

      <SLFooter />
    </>
  );
}

export default Careers;

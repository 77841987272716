

// import React from 'react';
import './Portal.css';
import INDFooter from "../Footer/Footer";
import React from "react";
import INDNavbar from "../Navbar/Navbar";
import INDChat from "../ChatBot/Chat";

const Portal = () => {
    return (
        <>
            <INDNavbar />
            <INDChat />
            <div className='portal'>
                <p><span className='one'>To be launch</span><span className='two'> Soon</span> </p>
            </div>
            <INDFooter />
        </>
    );
};

export default Portal;
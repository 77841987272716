import React, { forwardRef } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import "./Solutions.css";

const Solutions = forwardRef((_, ref) => {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleButtonClick = () => {
    navigate("/Global/Solutions"); // Navigate to the desired route
  };

  return (
    <section id="solutions" className="glb-solutions-section" ref={ref}>
      <div className="glb-solutions-container">
        {/* Left Content */}
        <div className="glb-solutions-left">
          <h1 className="glb-solutions-title">
            A world-leading <br />
            <span>technology ecosystem</span>
          </h1>
        </div>

        {/* Right Content */}
        <div className="glb-solutions-right">
          <p className="glb-solutions-description">
            Our mission is to enable a seamless, trusted digital future powered
            by a best-in-class technology portfolio. By combining innovation
            with trusted market leaders, we deliver optimized cybersecurity and
            digital infrastructure solutions tailored to evolving business
            needs.
          </p>
          <button className="glb-solutions-button" onClick={handleButtonClick}>
            Explore Our Solutions
          </button>
        </div>
      </div>
    </section>
  );
});

export default Solutions;

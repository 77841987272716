import './Solutions.css';
import Authitianit from '../../images/pillers/Authitianit.jpg';
import cyber from '../../images/pillers/cyber.jpg';
import endpoint from '../../images/pillers/endpoint.jpg';
import datapoint from '../../images/pillers/Datapoint.jpg';
import Security from '../../images/pillers/Security.jpg';
import networking from '../../images/pillers/networking.jpg';
import Perimie from '../../images/pillers/Perimie.jpg';
import servers from '../../images/pillers/severBackup.jpg';
import mrcst from '../../images/pillers/Microsoft.jpg';
import smartiot from '../../images/pillers/IOT.jpg';
import ucc from '../../images/pillers/UCC.png';
import accessories from '../../images/pillers/CPA.jpg';
import aviot from '../../images/pillers/AVIoT.png';
import { NavLink } from "react-router-dom";
import INDNavbar from "../Navbar/Navbar";
import INDChat from "../ChatBot/Chat";
import React from "react";
import INDFooter from "../Footer/Footer";

function Solutions() {
  const pillars = [
    { img: Perimie, text: "Perimeter and Internal Security", link: "/in/Solutions/Perimia" },
    { img: cyber, text: "Cyber Security Governance & Compliance", link: "/in/Solutions/CyberSecurity" },
    { img: Authitianit, text: "Authentication & Identity Management", link: "/in/Solutions/Authentication" },
    { img: Security, text: "Security Management", link: "/in/Solutions/SecurityManagement" },
    { img: datapoint, text: "Endpoint Security", link: "/in/Solutions/EndpointSecurity" },
    { img: networking, text: "Networking", link: "/in/Solutions/Networking" },
    { img: endpoint, text: "Data Center Infrastructure and Monitoring", link: "/in/Solutions/Infrastructure" },
    { img: servers, text: "Server Storage & Backup Solutions", link: "/in/Solutions/Server" },
   
    { img: smartiot, text: "Smart and IoT Devices", link: "/in/Solutions/IOT" },
    { img: ucc, text: "Unified Communications and Collaboration", link: "/in/Solutions/UCC" },
    { img: accessories, text: "Computer and Phone Accessories", link: "/in/Solutions/Cpa" },
    { img: aviot, text: "Audio and Video IoT", link: "/in/Solutions/AVIoT" },
    { img: mrcst, text: "Microsoft", link: "/in/Solutions/Microsoft" },
  ];

  return (
      <>
        <INDNavbar />
        <INDChat />
        <section id='hero' className='hero1 heroSolution'>
          <div className="container text-center">
            <div className="row text">
              <div className="col-4" data-aos="fade-up" data-aos-delay="100"><hr /></div>
              <div className="col-4" data-aos="fade-up" data-aos-delay="100"><p id='topic'>Solutions</p></div>
              <div className="col-4" data-aos="fade-up" data-aos-delay="100"><hr /></div>
            </div>
            <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-3">
              {pillars.map((pillar, index) => {
                const isLast = index === pillars.length - 1;
                return (
                  <div
                    className={`col ${isLast ? "mx-auto" : ""}`}
                    key={index}
                  >
                    <NavLink to={pillar.link} activeClassName='active-link'>
                      <div className="card crd h-100">
                        <img src={pillar.img} className="card-img-top" alt={pillar.text} />
                        <div className="card-body d-flex align-items-center justify-content-center">
                          <p className="card-text txts text-center">{pillar.text}</p>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <INDFooter />
      </>
  );
}

export default Solutions;

import React from 'react';
import './RegSec2.css';

function RegSec2() {
    const regionData = [
        {
            region: "Asia",
            countries: [
                {
                    name: "Sri Lanka",
                    address: "286, R A De Mal Mawatha, Colombo 3, Sri Lanka 00300",
                    phone: " +94 114 693 355",
                    contactUrl: "https://www.connexit.biz/SL/ContactUs"
                },
                {
                    name: "Cambodia",
                    address: `11F-12, Morgan Tower,
Sopheakmongkul Street,
Village 14, Sangkat Tonle Bassac,
Khan Chamkarmon, Phnom Penh, Cambodia`,
                    phone: "+85586677044",
                    contactUrl: "https://www.connexit.biz/kh/ContactUs"
                },
                {
                    name: "Singapore",
                    address: "60, Paya Lebar Road, #06-39, Paya Lebar Square, Singapore 409051",
                    phone: "+6567278910",
                    contactUrl: "https://www.connexit.biz/SL/ContactUs"
                },
                {
                    name: "Brunei",
                    address: `Connex Information Technologies Sdn Bhd
Unit B20, Block B, First Floor, Bangunan HABZA,
Simpang 150, Kg Kiulap, Bandar Seri Begawan BE1518
Negara Brunei Darussalam.`,
                    phone: "+673 223 3575",
                    contactUrl: "https://www.connexit.biz/BRN/ContactUs"
                },
                {
                    name: "Thailand",
                    address: `184/79, Forum Tower Building, 17th Floor, Ratchadaphisek Road,
Huai Khwang, Bangkok 10310`,
                    phone: "+66612700590",
                    contactUrl: "https://www.connexit.biz/TH/ContactUs"
                },
                {
                    name: "India",
                    address: `220 3rd Double Rd Domlur Indiranagar, 2nd Phase,
Domlur Bangalore North Bangalore KA 560071, India`,
                    phone: "+91 789 933 9059",
                    contactUrl: "https://www.connexit.biz/SL/ContactUs"
                },
                {
                    name: "Nepal",
                    address: "Baluwatar-4, Kathmandu, Nepal",
                    phone: "+977-9841592542",
                    contactUrl: "https://www.connexit.biz/NPL/ContactUs"
                }
            ]
        },
        {
            region: "Oceania",
            countries: [
                {
                    name: "Australia",
                    address: "14 Alwyn Crescent, Glenwood NSW 2767",
                    phone: "+61 406 125 445",
                    contactUrl: "https://www.connexit.biz/AU/ContactUs"
                },
                {
                    name: "New Zealand",
                    address: "CONNEX INFORMATION TECHNOLOGIES LIMITED,Plimmer Towers , 2-6 Glimmer Terrace,Wellington, 6011",
                    phone: "0226892981",
                    contactUrl: "https://www.connexit.co.nz/ContactUs"
                }
            ]
        },
        {
            region: "Middle East",
            countries: [
                {
                    name: "Dubai",
                    address: `P.O. BOX: 410714
Office 10, 806, 8th Floor,
Opal Tower, Business Bay,
Dubai, UAE`,
                    phone: "+4 424 9988",
                    contactUrl: "https://www.connexit.biz/uae/ContactUs"
                }
            ]
        },
        {
            region: "Africa",
            countries: [
                {
                    name: "Mauritius",
                    address: `Office C-03,
Ebene Junction,
Ebene, Quatre Bornes,
Mauritius`,
                    phone: "+23 05 942 8354",

                    contactUrl: "https://www.connexit.biz/MU/ContactUs"
                }
            ]
        }
    ];

    const handleRedirect = (url) => {
        window.location.href = url; // Redirect to the specified URL
    };

    return (
        <div className="reg-sec2-outer">
            {regionData.map((region) => (
                <div className="reg-sec2-container" key={region.region}>
                    <div className="reg-sec2-header">
                        <h1>{region.region}</h1>
                    </div>
                    <div className="reg-sec2-content">
                        {region.countries.map((country) => (
                            <div className="reg-sec2-location" key={country.name}>
                                <h2>{country.name}</h2>
                                <ul>
                                    <li><i className="fas fa-map-marker-alt"></i> {country.address}</li>
                                    <li><i className="fas fa-phone"></i> {country.phone}</li>
                                    <li>
                                        <i className="fas fa-envelope"></i>
                                        <span
                                            className="contact-us-link"
                                            onClick={() => handleRedirect(country.contactUrl)}
                                        >
                                            Contact Us Form
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default RegSec2;



// import React from 'react';
import './Portal.css';
import SGFooter from "../Footer/Footer";
import React from "react";
import SGNavbar from "../Navbar/Navbar";
import SGChat from "../ChatBot/Chat";

const Portal = () => {
    return (
        <>
            <SGNavbar />
            <SGChat />
            <div className='portal'>
                <p><span className='one'>To be launch</span><span className='two'> Soon</span> </p>
            </div>
            <SGFooter />
        </>
    );
};

export default Portal;
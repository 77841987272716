import React from 'react';
import './Sec1.css';

function Sec1() {
  const scrollToNextSection = () => {
    const nextSection = document.querySelector('.next-section');
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className="solutionglb">
        <h1 className="main-heading">
          World-Leading Cybersecurity and Digital Infrastructure Solutions
        </h1>
        <p className="subheading">
          Security is paramount, but ‘trust’ is more than this. No trust. No transformation.
        </p>
        <div className="scroll-indicator" onClick={scrollToNextSection}>
          <i className="fas fa-mouse"></i>
        </div>
      </div>

      <section className="next-section">
        <h1 className="next-main-heading">
          Forever relevant <br />
          cybersecurity solutions
        </h1>
        <div className="three-columns">
          <div className="column"></div>
          <div className="column middle-column">
            <p className="next-subheading">
              We are helping to drive the transition to a totally trusted digital world through our network security solutions.
              It’s a living evolution. Always relevant, never standing still, continually reinventing.
            </p>
          </div>
          <div className="column"></div>
        </div>
      </section>
    </>
  );
}

export default Sec1;
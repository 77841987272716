import React from 'react'
import GLBNavbar from "../Navbar/Navbar";
import './Solution.css';
import Sec1 from './Section1/Sec1';
import Sec2 from './Section2/Sec2'
import Footerglb from "../Footer/Footer"


function Solution() {
    return (
        <>
            <GLBNavbar />
            <Sec1 />
            <Sec2 />
            <Footerglb />


        </>
    )
}

export default Solution


// import React from 'react';
import './Portal.css';
import BGDFooter from "../Footer/Footer";
import React from "react";
import BGDNavbar from "../Navbar/Navbar";
import BGDChat from "../ChatBot/Chat";

const Portal = () => {
    return (
        <>
            <BGDNavbar />
            <BGDChat />
            <div className='portal'>
                <p><span className='one'>To be launch</span><span className='two'> Soon</span> </p>
            </div>
            <BGDFooter />
        </>
    );
};

export default Portal;
import React from 'react'
import GLBNavbar from "../Navbar/Navbar";
import RegSec1 from "./RegSec1/RegSec1"
import RegSec2 from './RegSec2/RegSec2';
import Footerglb from "../Footer/Footer"


function Regions() {
  return (
    <>
    <GLBNavbar />
    <RegSec1 />
    <RegSec2 />
    <Footerglb />


</>
  )
}

export default Regions
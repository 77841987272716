import './Solutions.css';
import Authitianit from '../../../images/pillers/Authitianit.jpg';
import cyber from '../../../images/pillers/cyber.jpg';
import endpoint from '../../../images/pillers/endpoint.jpg';
import datapoint from '../../../images/pillers/Datapoint.jpg';
import Security from '../../../images/pillers/Security.jpg';
import networking from '../../../images/pillers/networking.jpg';
import Perimie from '../../../images/pillers/Perimie.jpg';
import servers from '../../../images/pillers/severBackup.jpg';
import mrcst from '../../../images/pillers/Microsoft.jpg';
import smartiot from '../../../images/pillers/IOT.jpg';
import ucc from '../../../images/pillers/UCC.png';
import accessories from '../../../images/pillers/CPA.jpg';
import aviot from '../../../images/pillers/AVIoT.png';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import BrnNavbar from '../Navbar/Navbar';
import BrnChat from '../ChatBot/Chat';
import BrnFooter from '../Footer/Footer';

function Solutions() {
  const { t } = useTranslation();
  const { Brnmaintopic, BrnperiTopic, BrncyberTopic, BrnauthTopic, BrnsecTopic, BrnendTopic, BrnnetTopic, BrndataTopic, BrnserverTopic, BrnsmartTopic, BrnuccTopic, BrnaccessoriesTopic, BrnaviotTopic, BrnmicrosoftTopic } = t('Brnsolutionssec', { returnObjects: true });

  // Define the 13 pillars
  const pillars = [
    { img: Perimie, text: BrnperiTopic, link: "/brn/Solutions/InternetSecurity" },
    { img: cyber, text: BrncyberTopic, link: "/brn/Solutions/CyberSecurity" },
    { img: Authitianit, text: BrnauthTopic, link: "/brn/Solutions/Authentication" },
    { img: Security, text: BrnsecTopic, link: "/brn/Solutions/SecurityManagement" },
    { img: datapoint, text: BrnendTopic, link: "/brn/Solutions/EndpointSecurity" },
    { img: networking, text: BrnnetTopic, link: "/brn/Solutions/Networking" },
    { img: endpoint, text: BrndataTopic, link: "/brn/Solutions/Infrastructure" },
    { img: servers, text: BrnserverTopic, link: "/brn/Solutions/Server" },
    { img: smartiot, text: BrnsmartTopic, link: "/brn/Solutions/IOT" },
    { img: ucc, text: BrnuccTopic, link: "/brn/Solutions/UCC" },
    { img: accessories, text: BrnaccessoriesTopic, link: "/brn/Solutions/CPA" },
    { img: aviot, text: BrnaviotTopic, link: "/brn/Solutions/AVIoT" },
    { img: mrcst, text: BrnmicrosoftTopic, link: "/brn/Solutions/Microsoft" },
  ];

  return (
    <>
      <BrnNavbar />
      <BrnChat />
      <section id='hero' className='hero1'>
        <div className="container text-center">
          <div className='row'>
            <div className="row text">
              <div className="col-4" data-aos="fade-up" data-aos-delay="100"><hr /></div>
              <div className="col-4" data-aos="fade-up" data-aos-delay="100"><p id='topic'>{Brnmaintopic}</p></div>
              <div className="col-4" data-aos="fade-up" data-aos-delay="100"><hr /></div>
            </div>
          </div>

          {/* Pillars displayed in rows of 4 */}
          <div className="row row-cols-1 row-cols-lg-4 g-3 align-items-center justify-content-center">
            {pillars.map((pillar, index) => (
              <div className="col" key={index}>
                <NavLink to={pillar.link} activeClassName='active-link'>
                  <div className="card crd">
                    <img src={pillar.img} className="card-img-top uniform-img" alt={pillar.text} />
                    <div className="card-body-brunai d-flex align-items-center justify-content-center">
                      <p className="card-text txts">{pillar.text}</p>
                    </div>
                  </div>
                </NavLink>
              </div>
            ))}
          </div>
        </div>
      </section>
      <BrnFooter />
    </>
  );
}

export default Solutions;
